<template>
  <div class="page-layout__inner with-layout-info-sidebar">
    <div class="page-layout__content">
      <ul class="nav nav-tabs" role="tablist">
        <li v-if="hasPermission('application.profile.contacts')" class="nav-item" @click="tabSelect('contacts')">
          <a class="nav-link" :class="{'active':tabs.contacts}" href="javascript:void(0)">{{ getTranslation('contacts') }}</a>
        </li>
        <li v-if="hasPermission('application.get')" class="nav-item" @click="tabSelect('addresses')">
          <a class="nav-link" :class="{'active':tabs.addresses}" href="javascript:void(0)">{{ getTranslation('addresses') }}</a>
        </li>
        <li v-if="hasPermission('application.profile.parcels')" class="nav-item" @click="tabSelect('parcels')">
          <a class="nav-link" :class="{'active':tabs.parcels}" href="javascript:void(0)">{{ getTranslation('parcels') }}</a>
        </li>
        <li v-if="hasPermission('application.profile.companies')" class="nav-item" @click="tabSelect('companies')">
          <a class="nav-link" :class="{'active':tabs.companies}" href="javascript:void(0)">{{ getTranslation('appCompanies') }}</a>
        </li>
        <li v-if="hasPermission('application.profile.tracking')  && item.status === 'CONFIRMED'" class="nav-item" @click="tabSelect('tracking')">
          <a class="nav-link" :class="{'active':tabs.tracking}" href="javascript:void(0)">{{ getTranslation('shipmentTracking') }}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div v-if="hasPermission('application.profile.contacts')" class="tab-pane" :class="{'active':this.tabs.contacts}">
          <AppContacts :active="tabs.contacts" :app="item"/>
        </div>
        <div v-if="hasPermission('application.get')" class="tab-pane" :class="{'active':this.tabs.addresses}">
          <AppAddresses :active="tabs.addresses" v-on:updateApp="fetchApp($route.params.id)" :app="item"/>
        </div>
        <div v-if="hasPermission('application.profile.parcels')" class="tab-pane" :class="{'active':this.tabs.parcels}">
          <AppParcelsComponent :active="tabs.parcels" :app="item" :app-id="item.id" :service-category-id="item.service_category_id"/>
        </div>
        <div v-if="hasPermission('application.profile.companies')" class="tab-pane" :class="{'active':this.tabs.companies}">
          <AppCompaniesComponent :latest-tarif="latestTarif" :active="tabs.companies" :app-id="item.id" :app="item" v-on:updateApp="fetchApp($route.params.id)"/>
        </div>
        <div v-if="hasPermission('application.profile.tracking') && item.status === 'CONFIRMED'" class="tab-pane" :class="{'active':this.tabs.tracking}">
          <ApplicationTrackingComponent :active="tabs.tracking" :app-id="item.id" :app="item"/>
        </div>
      </div>
    </div>

    <div class="page-layout__info-sidebar">
      <div class="card-body" v-if="item.id">
        <div class="mb-2">
          <span v-if="item.by_land" class="badge badge-pill badge-warning">{{ getTranslation('appByLand') }}</span>
          <span v-if="item.needs_border_service" class="badge badge-pill badge-info ml-2">{{ getTranslation('appNeedsBorderService') }}</span>
          <span v-if="item.is_call_requested" class="badge badge-pill badge-danger ml-2">{{ getTranslation('callRequested') }}</span>
          <span v-if="item.express" class="badge badge-pill badge-primary ml-2">{{ getTranslation('expressCargo') }}</span>
        </div>

        <div v-if="item.company" class="alert alert-primary">
          <span class="info__data-label">{{ getTranslation('company') }}</span>
          <p>
            <router-link :to="{name:'company.get',params:{id:item.company.id}}" class="font-weight-bolder">{{ item.company.title }}</router-link>
          </p>
        </div>

        <div v-if="item.coupon" class="alert alert-success" role="alert">
          <span class="info__data-label">{{ getTranslation('coupon') }}</span>
          <p><a href="javascript:void(0)" class="font-weight-bolder">{{ item.coupon.code }}</a></p>
        </div>

        <div v-if="item.request_status" class="alert" :class="{ 'alert-success' : item.request_status === 'TARIF_SENT' || item.request_status === 'TARIF_ACCEPTED'
         || item.request_status === 'INVOICE_SENT',
        'alert-danger' : item.request_status === 'INVOICE' || item.request_status === 'TARIF'}" role="alert">
          <p>{{ getTranslation('REQUEST_' + item.request_status) }}</p>
          <span v-if="item.request_status === 'TARIF' && this.latestTarif.price_transport" class="line-through" >
            {{ this.latestTarif.price_transport }} {{ this.latestTarif.currency }}
          </span>
          <span v-if="item.request_status === 'TARIF' && this.latestTarif.id" class="ml-2">
            {{ this.latestTarif.tarif }} {{ this.latestTarif.currency }}
          </span>
        </div>

        <div>
          <span class="info__data-label">{{ getTranslation('serviceCategory') }}</span>
          <p class="font-weight-bolder font-italic">{{ getTranslation(item.service_category.slug) }}</p>
        </div>

        <div>
          <span class="info__data-label">{{ getTranslation('serviceOptions') }}</span>
          <p class="font-italic">
            <span v-if="item.by_land">{{ item.service_option.title.land }}</span>
            <span v-else>{{ item.service_option.title.air }}</span>
          </p>
        </div>

        <div class="c-callout c-callout-dark">

          <div>
            <span class="info__data-label">{{ getTranslation('applicationCode') }}</span>
            <p class="info__data-value">{{ item.code }}</p>
          </div>

          <div v-if="item.awb">
            <span class="info__data-label">{{ getTranslation('applicationAWB') }}</span>
            <p class="info__data-value">{{ item.awb }}</p>
          </div>

        </div>

        <div class="c-callout c-callout-info">
          <div>
            <span class="info__data-label">{{ getTranslation('originCountry') }}</span>
            <p class="info__data-value">{{ item.origin_country.title }} ({{ item.origin_country.code }})</p>
          </div>

          <div v-if="item.origin_city" class="mt-2">
            <span class="info__data-label">{{ getTranslation('originCity') }}</span>
            <p class="info__data-value">{{ item.origin_city.title }} ({{ item.origin_city.code }})</p>
          </div>
          <div>
            <span class="info__data-label">{{ getTranslation('destinationCountry') }}</span>
            <p class="info__data-value">{{ item.destination_country.title }} ({{ item.destination_country.code }})</p>
          </div>

          <div v-if="item.destination_city" class="mt-2">
            <span class="info__data-label">{{ getTranslation('destinationCity') }}</span>
            <p class="info__data-value">{{ item.destination_city.title }} ({{ item.destination_city.code }})</p>
          </div>

          <div v-if="item.destination_airport" class="mt-2">
            <span class="info__data-label">{{ getTranslation('destinationAirport') }}</span>
            <p class="info__data-value">{{ item.destination_airport.title }} ({{ item.destination_airport.iata_code }})</p>
          </div>
        </div>

        <div v-if="item.user">
          <span class="info__data-label">{{ getTranslation('appAdder') }}</span>
          <p class="info__data-value">{{ item.user.name }}</p>
        </div>


        <div>
          <span class="info__data-label">{{ getTranslation('shipmentDate') }}</span>
          <p class="info__data-value">{{ item.shipment_date }}</p>
        </div>

        <div>
          <span class="info__data-label">{{ getTranslation('addedDate') }}</span>
          <p class="info__data-value">{{ item.creation_date }}</p>
        </div>

        <div v-if="item.description">
          <span class="info__data-label font-weight-bold">{{ getTranslation('description') }}</span>
          <p class="info__data-value">{{ item.description }}</p>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import AppParcelsComponent from "@/views/admin/applications/components/AppParcelsComponent";
import AppCompaniesComponent from "@/views/admin/applications/components/AppCompaniesComponent";
import AppContacts from "@/views/admin/applications/components/AppContacts";
import AppAddresses from "@/views/admin/applications/components/AppAddresses";
import ApplicationTrackingComponent from "./components/ApplicationTrackingComponent";

export default {
  name: "AppView",
  components: { ApplicationTrackingComponent, AppAddresses, AppContacts, AppCompaniesComponent, AppParcelsComponent },
  data() {
    return {
      item: {
        id: null
      },
      latestTarif: {
        id: null,
      },
      suitableCompanies: [],
      companies: [],
      companyPagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      suitableCompanyPagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      tabs: {
        companies: false,
        contacts: false,
        addresses: false,
        parcels: false,
        serviceOptions: false,
        suitableCompanies: false,
        allCompanies: false,
        tracking: false,
      },
      showModal: false
    }
  },
  created() {
    this.fetchApp(this.$route.params.id).then(() => {
      this.getLatestTarifRequest();

      if ( this.hasPermission('application.profile.contacts') )
        this.tabSelect('contacts',);
      else
        this.tabSelect('addresses');

    });
  },
  beforeDestroy() {
    this.$store.dispatch('heading/resetAppInfo');
  },
  methods: {

    updateHeadings() {
      let actions = [];

      if ( this.hasPermission('application.profile.sendCompanies') && this.item.status === 'NEW' )
        actions.push(
            {
              label: this.getTranslation('sendToCompanies'),
              action: this.sendCompanies,
              c_icon: 'cil-send'
            }
        );

      if ( this.hasPermission('application.profile.invoice') && this.item.status === 'CONFIRMED' )
        actions.push(
            {
              label: this.getTranslation('invoice'),
              action: () => {
                this.$router.push({ name: 'application.profile.invoice', params: { id: this.$route.params.id } })
              },
              c_icon: 'cil-clipboard',
              buttonClass: 'btn-info'
            }
        );

      if ( this.hasPermission('application.profile.confirm') && this.item.status === 'WAITING_PROVIDER' )
        actions.push(
            {
              label: this.getTranslation('confirm'),
              action: this.confirm,
              icon: {
                icon: '#icon__shape-check',
                classes: 'btn__icon--success'
              }
            }
        );

      if ( this.hasPermission('application.profile.payment') && this.item.status === 'CONFIRMED' && !this.item.is_client_paid )
        actions.push(
            {
              label: this.getTranslation('paid'),
              action: this.clientPaid,
              c_icon: 'cil-cash',
              buttonClass: 'btn-danger'
            }
        );

      this.setHeadings({
        title: this.item.title,
        toBack: {
          label: this.getTranslation('toBack', this.getTranslation('applications')),
          route: { name: 'application.getList' }
        },
        actions: actions,
        breadcrumbs: [
          {
            to: { name: 'application.getList' },
            text: this.getTranslation('applications')
          },
          {
            text: this.item.title
          }
        ],
      });
    },

    getLatestTarifRequest() {
      this.axios.get(this.$urls.application.requestedTarifs.url.replace(':id', this.item.id), { params: { latest: 1 } })
          .then(resp => {
            if ( resp.data.data.item ) {
              this.latestTarif = resp.data.data.item;
            }
            if ( resp.data.data.selectedAppCompany ) {
              this.latestTarif.currency = resp.data.data.selectedAppCompany.currency.code;
            }
            if ( resp.data.data.latestActivePrice ) {
              this.latestTarif.price_transport = resp.data.data.latestActivePrice.price_transport;
            }
          }).catch(() => {
      });
    },

    fetchApp(id) {
      return this.axios.get(this.$urls.application.application.url.replace(':id', id))
          .then(response => {
            this.item = response.data.data;
            let appInfo = {};
            appInfo.status = this.item.status;
            appInfo.is_client_paid = this.item.is_client_paid;

            this.$store.dispatch('heading/setAppInfo', appInfo);

            this.updateHeadings();

          }).catch(() => {
          });
    },
    sendCompanies() {
      Swal.fire({
        title: this.getTranslation('sendToCompanies') + ' ?',
        text: this.getTranslation("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {
          this.tabSelect('parcels');
          this.axios.post(this.$urls.application.sendCompanies.url.replace(':id', this.$route.params.id))
              .then(response => {
                this.tabSelect('companies');
                this.snotifySuccess('operationSuccess');
                this.fetchApp(this.$route.params.id);
              }).catch(() => {
          });

        }
      })
    },
    confirm() {
      Swal.fire({
        title: this.getTranslation('areYouSure'),
        text: this.getTranslation("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('OK')
      }).then((result) => {

        if ( result.isConfirmed ) {
          this.tabSelect('parcels');
          this.axios.post(this.$urls.application.confirm.url.replace(':id', this.$route.params.id))
              .then(response => {
                this.snotifySuccess('operationSuccess');
                this.fetchApp(this.$route.params.id);
              }).catch(() => {
          });

        }
      })
    },
    clientPaid() {
      Swal.fire({
        title: this.getTranslation('isClientMadePayment') + '?',
        text: this.getTranslation("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('yes'),
        cancelButtonText: this.getTranslation('cancel'),
      }).then((result) => {

        if ( result.isConfirmed ) {
          this.tabSelect('tracking');
          this.axios.post(this.$urls.application.payment.url.replace(':id', this.$route.params.id), { type: 'client' })
              .then(response => {
                this.snotifySuccess('operationSuccess');
                this.fetchApp(this.$route.params.id);
              }).catch(() => {
          });

        }
      })
    }
  }
}
</script>

<style scoped>

</style>