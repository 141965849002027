<template>
  <section :class="sectionClass">
    <div class="section-header">
      <div class="section__controls">
        <div class="section__controls-group">
          <button v-if="hasPermission('application.item.addAddress')" type="button" @click="editAddress" class="btn btn-sm btn-info">{{ getTranslation('editAddress') }}</button>
        </div>
      </div>
    </div>
    <div class="section__body">
      <div class="section__group col-md-9 col-sm-12">
        <table v-if="app.id" class="table-doc">
          <tbody>
          <tr>
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('originCountry') }}:</th>
            <td class="table-doc__cell">{{ app.origin_country.fullText }}</td>
          </tr>
          <tr v-if="app.origin_city_id">
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('originCity') }}:</th>
            <td class="table-doc__cell">{{ app.origin_city.fullText }}</td>
          </tr>
          <tr v-if="app.origin_airport">
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('originAirport') }}:</th>
            <td class="table-doc__cell">{{ app.origin_airport.fullText }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('originAddress') }}:</th>
            <td class="table-doc__cell">{{ app.address_origin }}</td>
          </tr>
          <tr>
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('destinationCountry') }}:</th>
            <td class="table-doc__cell">{{ app.destination_country.fullText }}</td>
          </tr>
          <tr v-if="app.destination_city">
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('destinationCity') }}:</th>
            <td class="table-doc__cell">{{ app.destination_city.fullText }}</td>
          </tr>
          <tr v-if="app.destination_airport">
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('destinationAirport') }}:</th>
            <td class="table-doc__cell">{{ app.destination_airport.fullText }}</td>
          </tr>
          <tr>
            <th class="table-doc__cell table-doc__cell--head">{{ getTranslation('destinationAddress') }}:</th>
            <td class="table-doc__cell">{{ app.address_destination }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal
        :title="getTranslation('editAddress')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal"
        cancel-button-label="close"
        ok-button-label="save"
        @clickOK="save"
    >
      <CForm>


        <div v-if="active">
          <CountrySelect v-if="app.id" :form-label="'originCountry'" required :add-class="'col-md-12'" :edit-country-id="app.origin_country_id" v-model="form.origin_country_id"/>

          <CitySelect  v-if="app.id && form.origin_country_id" :add-class="'col-md-12'" :form-label="'destinationCity'" :country-id="form.origin_country_id" :edit-item-id="app.origin_city_id"
                      v-model="form.origin_city_id"/>

          <AirportSelect  v-if="app.id && form.origin_city_id && !form.by_land" :add-class="'col-md-12'" :form-label="'destinationAirport'" :edit-item-id="app.origin_airport_id" v-model="form.origin_airport_id"/>

          <div class="form-group col-12">
            <label class="form__label form__label--block">{{ getTranslation('originAddress') }}<span class="required">*</span></label>
            <textarea class="form-control field" :class="{'is-invalid':$v.form.address_origin.$error}" rows="2" v-model="form.address_origin"></textarea>
          </div>

          <CountrySelect v-if="app.id" :form-label="'destinationCountry'" :edit-country-id="app.destination_country_id" required :add-class="'col-md-12'" v-model="form.destination_country_id"/>

          <CitySelect v-if="app.id && form.destination_country_id" :add-class="'col-md-12'" :form-label="'destinationCity'" :edit-item-id="app.destination_city_id" :country-id="form.destination_country_id"
                      v-model="form.destination_city_id"/>

          <AirportSelect v-if="app.id && form.destination_city_id && !form.by_land" :add-class="'col-md-12'" :edit-item-id="app.destination_airport_id" :form-label="'destinationAirport'" v-model="form.destination_airport_id"/>

          <div class="form-group col-12">
            <label class="form__label form__label--block">{{ getTranslation('destinationAddress') }}<span
                class="required">*</span></label>
            <textarea class="form-control field" :class="{'is-invalid':$v.form.address_destination.$error}" rows="2" v-model="form.address_destination"></textarea>
          </div>
        </div>

      </CForm>
    </modal>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import CountrySelect from "@/components/shared/CountrySelect";
import CitySelect from "@/components/shared/CitySelect";
import AirportSelect from "@/components/shared/AirportSelect";

export default {
  name: "AppAddresses",
  components: { AirportSelect, CitySelect, CountrySelect },
  props: {
    app: {
      type: Object,
      required: true
    },
    active: Boolean,
    sectionClass: {
      type:String,
      default:'section section--shadow-0'
    }
  },
  data() {
    return {
      showModal: false,
      showAirports: false,
      showCities: false,
      form: {
        origin_country_id: '',
        origin_city_id: '',
        origin_airport_id: '',
        address_origin: '',
        destination_country_id: '',
        destination_city_id: '',
        destination_airport_id: '',
        address_destination: ''
      }
    }
  },
  methods: {
    save() {
      this.$v.form.$touch();
      if ( this.$v.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.application.addAddress.url.replace(':id', this.app.id), this.form)
          .then(response => {
            this.snotifySuccess('saveSuccessMsg');
            this.$emit('updateApp');
            this.closeModal();
          }).catch(() => {
      });

    },
    editAddress() {

      this.showModal = true;

      this.form.origin_country_id = this.app.origin_country_id;
      this.form.origin_city_id = this.app.origin_city_id;
      this.form.origin_airport_id = this.app.origin_airport_id;

      this.form.destination_country_id = this.app.destination_country_id;
      this.form.destination_city_id = this.app.destination_city_id;
      this.form.destination_airport_id = this.app.destination_airport_id;

      this.form.address_destination = this.app.address_destination;
      this.form.address_origin = this.app.address_origin;

    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    resetForm() {
      this.form = {
        origin_country_id: '',
        origin_city_id: '',
        address_origin: '',
        destination_country_id: '',
        destination_city_id: '',
        destination_airport_id: '',
        address_destination: ''
      };
    }
  },
  validations: {
    form: {
      origin_country_id: { required },
      address_origin: { required },
      destination_country_id: { required },
      address_destination: { required },
    }
  }
}
</script>

<style scoped>

</style>