<template>
  <section class="section section--shadow-0">
    <div class="section-header">
      <div class="section__controls">
        <div class="section__controls-group">
          <button v-if="hasPermission('application.item.addParcel')" type="button" @click="openModal" class="btn btn-sm btn-info">{{ getTranslation('addParcel') }}</button>
        </div>
      </div>
    </div>
    <div class="section__body">
      <div class="table-3-wrapper table-responsive">
        <table v-if="serviceCategoryId === GENERAL_CARGO_ID || serviceCategoryId === PARCELS_ID" class="table table-borderless">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('quantity') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('weight') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('length') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('height') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('width') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('volume') }}
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(parcel ,key) in parcelItems" v-bind:key="parcel.id" class="table-3__row">
            <td class="table-3__cell font-weight-bold">
              {{ key + 1 }}
            </td>
            <td class="table-3__cell">
              {{ parcel.quantity }}
            </td>
            <td class="table-3__cell">
              {{ parcel.weight }} {{ parcel.weightUnit ? parcel.weightUnit.title : '' }}
            </td>
            <td class="table-3__cell">
              {{ parcel.length }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.height }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.width }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.volume }} {{ parcel.dimensionUnit.title }}
            </td>
          </tr>
          <tr class="table-3__row">
            <td class="table-3__cell"></td>
            <td class="table-3__cell">{{ totalQuantity }}</td>
            <td class="table-3__cell">{{ totalWeight }}</td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell">{{ totalVolume_m }} m<sup>3</sup></td>
          </tr>
          </tbody>
        </table>
        <table v-if="serviceCategoryId === PETS_LIVE_ID" class="table table-borderless">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('breed') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('animalType') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('age') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('isHouseHold') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('quantity') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('weight') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('length') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('height') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('width') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('volume') }} <sup>3</sup>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(parcel ,key) in parcelItems" v-bind:key="parcel.id" class="table-3__row">
            <td class="table-3__cell font-weight-bold">
              {{ key + 1 }}
            </td>
            <td class="table-3__cell">
              {{ parcel.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.animalType.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.age }} {{ parcel.ageUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.is_household ? getTranslation('yes') : getTranslation('no') }}
            </td>
            <td class="table-3__cell">
              {{ parcel.quantity }}
            </td>
            <td class="table-3__cell">
              {{ parcel.weight }} {{ parcel.weightUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.length }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.height }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.width }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.volume }}
            </td>
          </tr>
          <tr class="table-3__row">
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"><h5 class="h5 font-weight-bold">{{ getTranslation('total') }}</h5></td>
            <td class="table-3__cell">{{ totalQuantity }}</td>
            <td class="table-3__cell">{{ totalWeight }}</td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell">{{ totalVolume_m }} m<sup>3</sup></td>
          </tr>
          </tbody>
        </table>
        <table v-if="serviceCategoryId === PERISHABLES_ID || serviceCategoryId === PHARMA_ID || serviceCategoryId === MEDICAL_ID" class="table table-borderless">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 15%">
              {{ getTranslation(productLabel) }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('quantity') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('weight') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('length') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('height') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('width') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('volume') }}<sup>3</sup>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(parcel ,key) in parcelItems" v-bind:key="parcel.id" class="table-3__row">
            <td class="table-3__cell font-weight-bold">
              {{ key + 1 }}
            </td>
            <td class="table-3__cell">
              {{ parcel.parcelType.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.quantity }}
            </td>
            <td class="table-3__cell">
              {{ parcel.weight }} {{ parcel.weightUnit ? parcel.weightUnit.title : '' }}
            </td>
            <td class="table-3__cell">
              {{ parcel.length }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.height }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.width }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.volume }} {{ parcel.dimensionUnit.title }}
            </td>
          </tr>
          <tr class="table-3__row">
            <td class="table-3__cell"></td>
            <td class="table-3__cell"><h5 class="h5 font-weight-bold">{{ getTranslation('total') }}</h5></td>
            <td class="table-3__cell">{{ totalQuantity }}</td>
            <td class="table-3__cell">{{ totalWeight }}</td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell">{{ totalVolume_m }} m<sup>3</sup></td>
          </tr>
          </tbody>
        </table>
        <table v-if="serviceCategoryId === SPECIALTY_ID" class="table table-borderless">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 15%">
              {{ getTranslation('specialtyType') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('quantity') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('weight') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('length') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('height') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('width') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('volume') }}
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(parcel ,key) in parcelItems" v-bind:key="parcel.id" class="table-3__row">
            <td class="table-3__cell font-weight-bold">
              {{ key + 1 }}
            </td>
            <td class="table-3__cell">
              {{ parcel.parcelType.title }}
              <span v-if="parcel.parcelType.slug === 'specialty_vehicles'">
          ({{ getTranslation('wheelBase') + ': ' + parcel.wheel_base + ' ' + parcel.dimensionUnit.title }})
          </span>
              <span v-if="parcel.containerType">
          ({{ getTranslation('containerType') + ': ' + parcel.containerType.title }})
          </span>
            </td>
            <td class="table-3__cell">
              {{ parcel.quantity }}
            </td>
            <td class="table-3__cell">
              {{ parcel.weight }} {{ parcel.weightUnit ? parcel.weightUnit.title : '' }}
            </td>
            <td class="table-3__cell">
              {{ parcel.length }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.height }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.width }} {{ parcel.dimensionUnit.title }}
            </td>
            <td class="table-3__cell">
              {{ parcel.volume }} <sup>3</sup>
            </td>
          </tr>
          <tr class="table-3__row">
            <td class="table-3__cell"></td>
            <td class="table-3__cell"><h5 class="h5 font-weight-bold">{{ getTranslation('total') }}</h5></td>
            <td class="table-3__cell">{{ totalQuantity }}</td>
            <td class="table-3__cell">{{ totalWeight }}</td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell"></td>
            <td class="table-3__cell">{{ totalVolume_m }} m<sup>3</sup></td>
          </tr>
          </tbody>
        </table>
        <p v-if="parcelItems.length < 1" class="text empty">
          {{ getTranslation('notAddedYet') }}
        </p>
      </div>
    </div>

    <modal
        :title="getTranslation(edit ? 'editParcel':'addParcel')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal"
        cancel-button-label="close"
        ok-button-label="save"
        @clickOK="save"
        size="lg"
    >

      <!-- specialty -->
      <div v-if="serviceCategoryId === SPECIALTY_ID" class="mt-4">
        <div v-if="showServiceParameters" class="form-row">
          <label class="form__label form__label--block">{{ getTranslation("Please select what you'd like to ship:") }} <span
              class="required">*</span></label>
          <div v-for="(type,key) in serviceParameters.types" v-bind:key="key" class="col-md-6 col-sm-12">
            <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="specialtyType" :id="type.slug" :value="type.slug" v-model="specialtyType">
                <label class="form-check-label" :for="type.slug">
                  {{ getTranslation(type.title) }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!app.is_call_requested && serviceCategoryId!== DANGEROUS_GOODS_ID">
        <label class="form__label mt-4">{{ getTranslation('parcels') }} <span class="required">*</span></label>
        <a href="javascript:void(0)" :title="getTranslation('add')" class="btn  btn--system btn--white btn-sm"
           @click="addParcel(serviceCategoryId)">
          <svg class="btn__icon  btn__icon--sm">
            <use xlink:href="#icon__square-add"></use>
          </svg>
        </a>
      </div>

      <div v-if="specialtyType === 'specialty_human_remains'">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-row" v-if="showServiceParameters">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('typeOfRemains') }}
                    <span class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.options"
                      label="title"
                      v-model="parcel.parcel_type_id"
                      :reduce="option=>option.id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('airContainerType') }}
                    <span class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.airContainerTypes"
                      label="title"
                      :reduce="option=>option.id"
                      v-model="parcel.container_type_id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
          </CCardBody>
        </CCard>

      </div>

      <div v-if="specialtyType === 'specialty_valuables'">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-row" v-if="showServiceParameters">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('typeOfRemains') }}
                    <span class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.valuableTypes"
                      label="title"
                      v-model="parcel.parcel_type_id"
                      :reduce="option=>option.id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
          </CCardBody>
        </CCard>

      </div>

      <div v-if="specialtyType === 'specialty_vehicles' || specialtyType==='specialty_fashion'">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div v-if="specialtyType===50" class="form-row mt-3">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('vehicleWheelBase') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.wheel_base"/>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" disabled="disabled" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
          </CCardBody>
        </CCard>

      </div>

      <div v-if="serviceCategoryId === GENERAL_CARGO_ID || serviceCategoryId === PARCELS_ID || serviceCategoryId===HOME_MOVE_ID">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group" v-if="serviceCategoryId===HOME_MOVE_ID">
              <label class="form__label">{{ getTranslation('title') }}</label>
              <input type="text" class="field" v-model="parcel.title"/>
            </div>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>
            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>

      <!-- pets -->
      <div v-if="serviceCategoryId === PETS_LIVE_ID">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('animalKindToBook') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="serviceParameters.options"
                  label="title"
                  v-model="parcel.animal_kind"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
            <div v-if="parcel.animal_kind.children.length > 0" class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('animalTypeToBook') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="parcel.animal_kind.children"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="parcel.animal_type_id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('breed') }}</label>
              <input type="text" class="field" v-model="parcel.title"/>
            </div>
            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>
            <div class="form-group">
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" v-model="parcel.is_household" id="household">
                <label class="form-check-label form__label" for="household">
                  {{ getTranslation('isHousehold') }}
                </label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-md-3 col-sm-6">
                <label class="form__label">{{ getTranslation('age') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.age"/>
              </div>
              <div class="col-md-3 col-sm-6">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.age_unit_id">
                  <option v-for="unit in unitOptions.age" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
              <div class="col-md-3 col-sm-6">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-md-3 col-sm-6">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>

      <!-- perishables -->
      <div v-if="serviceCategoryId === PERISHABLES_ID">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('perishableProduct') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="serviceParameters.options"
                  label="title"
                  v-model="parcel.parcel_type"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
            <div v-if="parcel.parcel_type.children.length > 0" class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('commodity') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="parcel.parcel_type.children"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="parcel.parcel_type_id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>

            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>

          </CCardBody>
        </CCard>
      </div>

      <!-- pharma -->
      <div v-if="serviceCategoryId === PHARMA_ID">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('pharmaProduct') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="serviceParameters.options"
                  label="title"
                  v-model="parcel.parcel_type_id"
                  :reduce="option=>option.id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>

            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>

          </CCardBody>
        </CCard>
      </div>

      <!-- medical -->
      <div v-if="serviceCategoryId === MEDICAL_ID">
        <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
          <CCardHeader>
            <span class="card-title font-2xl"> № {{ key + 1 }}</span>
            <div class="card-header-actions">
              <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                      :disabled="parcels.length < 2">
                <CIcon name="cil-delete"/>
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation('medicalProduct') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="serviceParameters.options"
                  label="title"
                  v-model="parcel.parcel_type_id"
                  :reduce="option=>option.id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>

            <div class="form-group">
              <label class="form__label">{{ getTranslation('quantity') }}</label>
              <input type="number" min="0" class="field" v-model="parcel.quantity"/>
            </div>

            <div class="form-row">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('length') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('width') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('height') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                  <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="col-3">
                <label class="form__label">{{ getTranslation('weight') }}</label>
                <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
              </div>
              <div class="col-3">
                <label class="form__label">{{ getTranslation('units') }}</label>
                <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                  <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                </select>
              </div>
            </div>

          </CCardBody>
        </CCard>
      </div>


    </modal>

  </section>
</template>

<script>
import generalMixin from "@/components/mixins/General";
import parcelMixin from "@/views/admin/applications/mixins/parcels";
import applicationActionMixin from "@/views/admin/applications/mixins/applicationAction";
import required from "vuelidate/lib/validators/required";

export default {
  name: "AppParcelsComponent",
  mixins: [ generalMixin, parcelMixin, applicationActionMixin ],
  props: {
    active: {
      default: false
    },
    app: {
      required: true
    },
    appId: {
      required: true
    },
    serviceCategoryId: {
      required: true
    }
  },
  data() {
    return {
      showServiceParameters: false,
      showModal: false,
      edit: false,
      productLabel: 'perishableProduct',
      totalQuantity: 0,
      totalWeight: 0,
      totalVolume: 0,
      totalVolume_m: 0,
      v_unit: '',
      w_unit: '',
      specialtyType: '',
      parcelItems: []
    }
  },

  watch: {
    'active'(val) {
      if ( val )
        this.fetchParcels();
    },
    'specialtyType'(val) {

      this.parcels = [];
      this.addParcel(this.serviceCategoryId);

      if ( val === 'specialty_vehicles' || val === 'specialty_fashion' ) {
        this.parcels.map(item => {
          item.parcel_type_id = val;
          return item;
        })
      }
    },
  },
  created() {
    if ( this.active )
      this.fetchParcels();

    if ( this.serviceCategoryId && this.serviceCategoryId !== this.GENERAL_CARGO_ID )
      this.fetchServiceParameters(this.serviceCategoryId).then(() => {
        this.showServiceParameters = true;
      });

    this.fetchUnitOptions();
    this.fetchServiceOptions();

  },
  methods: {
    openModal() {
      this.showModal = true;
      this.parcels = [ ...this.parcelItems ];
    },
    save() {
      this.$v.parcels.$touch();

      if ( this.$v.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.application.addParcel.url.replace(':id', this.appId), {parcels:this.parcels})
          .then(response => {
            this.snotifySuccess('saveSuccessMsg');

            this.fetchParcels();
            this.closeModal();

          }).catch(() => {
      });

    },
    closeModal() {
      this.showModal = false;
    },

    fetchParcels() {
      return this.axios.get(this.$urls.application.applicationParcels.url.replace(':id', this.appId))
          .then(response => {

            if ( this.serviceCategoryId === this.PHARMA_ID )
              this.productLabel = 'pharmaProduct';

            if ( this.serviceCategoryId === this.MEDICAL_ID )
              this.productLabel = 'medicalProduct';

            this.parcelItems = response.data.data.parcels;
            this.totalQuantity = response.data.data.totalQuantity;
            this.totalVolume = response.data.data.totalVolume;
            this.totalVolume_m = response.data.data.totalVolume_m;
            this.totalWeight = response.data.data.totalWeight;
            this.v_unit = response.data.data.v_unit;
            this.w_unit = response.data.data.w_unit;

          }).catch(() => {
          });
    }
  },
  validations: {
    parcels: {
      required
    }
  }
}
</script>

<style scoped>

</style>