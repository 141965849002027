<template>
  <section :class="sectionClass">
    <div class="section-header">
      <div class="section__controls">
        <div class="section__controls-group">
          <button v-if="hasPermission('application.item.addContact')" type="button" @click="openModal" class="btn btn-sm btn-info">{{ getTranslation('addContacts') }}</button>
        </div>
      </div>
    </div>
    <div class="section__body">
      <div class="section__group col-md-12 col-sm-12">
        <table v-if="app.id" class="table-3">
          <thead class="table-3__head">
          <tr class="table-3__row">
            <td class="table-3__cell  table__hcell" style="width: 0.2%">
              #
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('contactName') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('pin') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('email') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('mobile') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('whatsapp') }}
            </td>
            <td class="table-3__cell  table__hcell" style="width: 10%">
              {{ getTranslation('type') }}
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in contacts" v-bind:key="item.id" class="table-3__row">
            <td class="table-3__cell">{{ item.id }}</td>
            <td class="table-3__cell">{{ item.name }}</td>
            <td class="table-3__cell">{{ item.pin }}</td>
            <td class="table-3__cell">{{ item.email }}</td>
            <td class="table-3__cell">
              <span v-if="item.mobileCode">{{ item.mobileCode.code }}</span>
              {{ item.mobile }}
            </td>
            <td class="table-3__cell">
              <span v-if="item.whatsapp && item.whatsappCode">{{ item.whatsappCode.code }}</span>
              {{ item.whatsapp }}
            </td>
            <td class="table-3__cell">{{ getTranslation(item.typeText) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal
        :title="getTranslation('addContacts')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal"
        cancel-button-label="close"
        ok-button-label="save"
        @clickOK="save"
    >
      <CForm>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('contactName') }} <span
              class="required">*</span></label>
          <input type="tel" class="field" v-model="contact.name" :class="{'is-invalid':$v.contact.name.$error}">
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('pin') }} <span
              class="required">*</span></label>
          <input type="tel" class="field" v-model="contact.pin" :class="{'is-invalid':$v.contact.pin.$error}">
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('type') }} <span
              class="required">*</span></label>
          <v-select
              :options="types"
              :reduce="option=>option.id"
              label="title"
              v-model="contact.type"
              :class="{'is-invalid':$v.contact.type.$error}"
              :placeholder="getTranslation('selectFromList')"
          />
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('mobile') }} <span
              class="required">*</span></label>

          <div v-if="showPhonCodes" class="input-group">

            <PhoneCode add-class="input-group-prepend" required :edit-item-id="contact.mobile_code_id" v-model="contact.mobile_code_id" :items="phoneCodes"/>

            <input type="tel" class="field form-control" v-model="contact.mobile" :class="{'is-invalid':$v.contact.mobile.$error}">
          </div>
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('whatsapp') }}</label>

          <div v-if="showPhonCodes" class="input-group">

            <PhoneCode add-class="input-group-prepend" required :edit-item-id="contact.whatsapp_code_id" v-model="contact.whatsapp_code_id" :items="phoneCodes"/>

            <input type="tel" class="field form-control" v-model="contact.whatsapp">
          </div>


        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('email') }} <span class="required">*</span></label>
          <input type="email" class="field" :class="{'is-invalid':$v.contact.email.$error}" v-model="contact.email">
        </div>
      </CForm>
    </modal>

  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import phoneMixin from "@/components/mixins/PhoneCode";
import PhoneCode from "@/components/shared/PhoneCode";

export default {
  name: "AppContacts",
  components: { PhoneCode },
  mixins: [ phoneMixin ],
  props: {
    app: {
      type: Object,
      required: true
    },
    active: Boolean,
    sectionClass: {
      type:String,
      default:'section section--shadow-0'
    }
  },
  data() {
    return {
      contacts: [],
      showModal: false,
      showTypes: false,
      types: [],
      contact: {
        name: '',
        pin: '',
        type: '',
        email: '',
        mobile: '',
        mobile_code_id: 118,
        whatsapp: '',
        whatsapp_code_id: 118,
      }
    }
  },

  watch: {
    'active'(val) {
      if ( val )
        this.fetchContact();
    }
  },
  created() {
    this.fetchContactTypes().then(() => {
      this.showTypes = true;
    });
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.$v.$reset();
      this.contact={
        name: '',
        type: '',
        email: '',
        pin: '',
        mobile: '',
        mobile_code_id: 118,
        whatsapp: '',
        whatsapp_code_id: 118,
      };
    },
    openModal() {
      this.showModal = true;
    },
    save() {
      this.$v.contact.$touch();

      if ( this.$v.contact.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.application.addContact.url.replace(':id', this.app.id), this.contact)
          .then(() => {
            this.fetchContact();
            this.snotifySuccess('saveSuccessMsg');
            this.closeModal();
          }).catch(() => {
      });

    },
    fetchContactTypes() {
      return this.axios.get(this.$urls.application.formOptions.url)
          .then(res => {
            this.types = this.objectToArray(res.data.data.contactTypes, true, true);
          }).catch(() => {
          });
    },
    fetchContact() {
      this.axios.get(this.$urls.application.contacts.url.replace(':id', this.app.id))
          .then(res => {
            this.contacts = res.data.data;
          }).catch(() => {
      });
    }
  },
  validations: {
    contact: {
      mobile: {
        required
      },
      mobile_code_id: {
        required
      },
      email: {
        required
      },
      name: {
        required
      },
      type: {
        required
      },
      pin: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>