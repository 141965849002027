import { render, staticRenderFns } from "./AppContacts.vue?vue&type=template&id=35fed821&scoped=true&"
import script from "./AppContacts.vue?vue&type=script&lang=js&"
export * from "./AppContacts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fed821",
  null
  
)

export default component.exports